<template>
  <v-row justify="center">
    <v-dialog v-model="shower" persistent max-width="500">
      <v-card scrollable persistent>
          <v-card-title>{{$t('relationMyfile.createrelation') }}<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35">device_hub</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              <v-layout row wrap align-center justify-center>
                  <v-flex xs11 lg11 ma-5>
                    <v-card>
                        <v-container fluid >
                            <v-row align="center">
                            <v-col cols="12">
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs10 class="text-center">
                                        <v-text-field
                                        :label="$t('relationMyfile.namerelation')"
                                        v-model="namerelation"
                                        persistent-hint
                                        outlined
                                        dense
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs10 class="text-center">
                                        <v-text-field
                                        :label="$t('relationMyfile.relation')"                                  
                                        v-model="filedata.file_name"
                                        persistent-hint
                                        outlined
                                        dense
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                  </v-flex>
              </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
             <v-btn
              color="red"
              outlined
              @click="$emit('closedialog'),$emit('openDrag')"
            >{{$t('uploadfolder.buttonclose') }}</v-btn>
            <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="apiAdd(),$emit('openDrag')"
            >{{$t('changeName.submit')}}</v-btn>

          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
//const editsharefile = () => import("../optional/dialog-editsharefile");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
  export default {
    props: ["show","filedata"],
    data () {
      return {
       relation:'',
       namerelation:'',
       statusrelation : [],
       dialog: false,
       fileinbox:[],
      }
    },
    computed:{
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
       shower: {
      get() {
        if (this.show === true) {
            // this.checkstatusrelation()
            console.log(this.show);
            
            //this.checkloaddata()
        }
        return this.show;
      }
    }
    },
    methods:{
        apiAdd(){
            //console.log(this.relation);
            
            let newdata = ''
            let payload;
            if(this.namerelation === ""){
                Toast.fire({
                icon: "error",
                title: "Error Please enter name "
              });
            }else{
            payload={
                data_id_root: this.filedata.file_id,
                data_type_root: this.filedata.file_type,
                account_id: this.dataAccountId,
                relation_name: this.namerelation
                }
            console.log("newdata",payload);
              this.axios
                .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/insert_relation_file_folder",payload)
                .then(response =>{
                    if (response.data.status === "OK") {
                        Toast.fire({
                            icon: "success",
                            title: "Success"
                         });
                        this.$emit('closedialog')
                        this.$emit('updatedatafile')
                        this.relation=''
                        this.namerelation=''
                    }else{
                        Toast.fire({
                            icon: "error",
                            title: "Error"
                         });
                    }
                    
                })
            }
        },
    // checkloaddata(){
    //     var payload = {
    //     user_id: this.dataUsername,
    //     account_id: this.dataAccountId,
    //     folder_id: this.account_active["directory_id"],
    //     status_folder: ""
    //   };
    //   // console.log(payload);

    //   this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_SELECT_FOLDER +
    //         "/api/v2/search_folder_byid",
    //       payload
    //     )
    //     .then(response => {
    //       console.log("Apifolder", response);
    //       // console.log("count",response.data.folder.length);
    //       if (response.data.status === "OK") {
    //           this.loaddataprogress=false;
    //           for(let i =0 ; i<response.data.folder.length;i++){
    //               if(response.data.folder[i]["relation_status"] === 2){
    //                   let datafolder = {}
    //                     datafolder["file_id"] = response.data.folder[i]["folder_id"];
    //                     datafolder["file_name"] = response.data.folder[i]["folder_name"];
    //                     datafolder["file_size"] = "-";
    //                     datafolder["file_lastdtm"] = response.data.folder[i]["cre_dtm"] || "-";
    //           datafolder["file_date"] = "";
    //           datafolder["file_linkshare"] = response.data.folder[i]["link"];
    //           datafolder["file_status_share"] = response.data.folder[i]["status_share"];
    //           datafolder["file_status_sharelink"] =response.data.folder[i]["status_share_link"];
    //           datafolder["file_status"] = response.data.folder[i]["folder_status"];
    //           datafolder["file_owner_eng"] = response.data.folder[i]["name_eng"];
    //           datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
    //           datafolder["file_type"] = "folder";
    //           datafolder["file_department"] = "";
    //           datafolder["file_icon"] = ["folder", "blue"];
    //           datafolder["file_parent_folder_id"] =response.data.folder[i]["parent_folder_id"];
    //           datafolder["file_permission"] =
    //             response.data.folder[i]["permission_setting"] === "True"
    //               ? response.data.folder[i]["permission_account"]
    //               : response.data.your_folder[0]["permission_account"];
    //           datafolder["file_permission_department_setting"] =
    //             response.data.folder[i]["permission_setting"] === "True"
    //               ? response.data.folder[i]["permission_department_setting"]
    //               : response.data.your_folder[0][
    //                   "permission_department_setting"
    //                 ];
    //           datafolder["file_permission_setting"] =
    //             response.data.folder[i]["permission_setting"] === "True"
    //               ? response.data.folder[i]["permission_setting"]
    //               : response.data.your_folder[0]["permission_setting"];
    //               datafolder["relation_id"]=response.data.folder[i]["relation_id"];
    //               datafolder["relation_name"]=response.data.folder[i]["relation_name"];
    //               datafolder["relation_status"]=response.data.folder[i]["relation_status"];
    //               this.statusrelation.push(datafolder);
    //               }
    //           }
    //       }});
    // }
    }
  }
</script>